import React, { useEffect, useState } from "react";
import Select from "react-select";
// import Loader from "react-loader-spinner";
import { useHistory } from "react-router-dom";
import moment from "moment";

import ValueCard from "./Cards/ValueCard";

import { getKpiFromOpenSearch, getKpiGraphData, getKpiValuesFromOpenSearchForPopularAssets } from "../../scripts/openSearchHandler";
import { formatAssetDuration, formatAssetNumberValue } from "../../scripts/assetUtils";

import { useMyContext } from "../../contexts/StateHolder";

import {
    // getCreatedAssets, getPublishedAssets,
    getCategories,
} from "../../scripts/dataHandlers";

import GenericChart from "../Analytics/Charts/GenericChart";

import Button from "../Analytics/Button";
import ModalDateRangePicker from "../Analytics/ModalDateRangePicker";
import Backdrop from "../Analytics/Backdrop";

// import MySelect from "./MySelect";

import SelectedDateRangeHeading from "../Analytics/SelectedDateRangeHeading";

// import LatestAssets from "./Grids/LatestAssets";
import LatestAndMostPopularAssets from "./LatestAndMostPopularAssets";
// import AssetsItem from "./AssetsItem";

import classes from "./VODAnalyticsDashboard.module.css";

//import MultilineChart from '../Analytics/Charts/MultilineChart';
//import Legend from '../Analytics/Charts/Legend';

//import schc from './SCHC.json';
//import vcit from './VCIT.json';
//import portfolio from './portfolio.json';

import { getLatestAssets, getSingleAsset } from "../../scripts/dataHandlers";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/*const portfolioData = {
    name: 'Portfolio',
    color: '#000000',
    items: portfolio.map((d) => ({ ...d, date: new Date(d.date) })),
};
const schcData = {
    name: 'SCHC',
    color: '#d53e4f',
    items: schc.map((d) => ({ ...d, date: new Date(d.date) })),
};
const vcitData = {
    name: 'VCIT',
    color: '#5e4fa2',
    items: vcit.map((d) => ({ ...d, date: new Date(d.date) })),
};*/
//const legendData = [portfolioData, schcData, vcitData];

/*const dimensions = {
    width: 1200,
    height: 400,
    margin: {
        top: 30,
        right: 30,
        bottom: 30,
        left: 60,
    },
};

const margin = {
    top: 30,
    right: 30,
    bottom: 30,
    left: 60,
};*/

// const videoOptions = [
//     { value: "all-videos", label: "All videos" },
//     { value: "my-videos", label: "My videos" },
// ];

const VODAnalyticsDashboard = (props) => {
    // For these, null means we haven't yet got any value, these should contain arrays after a while.
    const [latestAssets, setLatestAssets] = useState(null);
    const [popularAssets, setPopularAssets] = useState(null);
    const [popularAssetIds, setPopularAssetIds] = useState(null);
    const [allGroupItems, setAllGroupItems] = useState([]);

    const organizationId = props.organizationId;
    const secret = props.secret;
    const userId = props.userId;
    const assetId = null;
    const eventId = null;
    const groupItemId = props.groupItemId;

    // TODO: Get this from a menu that contain groupItemIds for different pages.
    // const groupItemId = "5585601";
    // suiterc example
    // http://192.168.1.80:3000/?organizationId=1909009&secret=kWXQ6KBCnN&groupItemId=5585601

    const history = useHistory();

    // console.log("SearchForAssets: org, user", organizationId, userId);

    // const { searchAssets, setSearchAssets } = useMyContext();
    const { searchAssetsString, setSearchAssetsString } = useMyContext();

    const createdBy = null; // userId;

    console.log("VODAnalyticsDashboard: orgId, userId, groupItemId", organizationId, userId, groupItemId);

    const getISODate = (d) => {
        const year = d.getFullYear();
        const month = d.getMonth() + 1;
        const day = d.getDate();

        const yearStr = "" + year;
        const monthStr = "" + (month >= 10 ? "" + month : "0" + month);
        const dayStr = "" + (day >= 10 ? "" + day : "0" + day);

        return "" + yearStr + "-" + monthStr + "-" + dayStr;
    };

    const startMoment1 = new moment().startOf("month");
    const endMoment1 = new moment().endOf("month");
    const startDate1 = startMoment1.toDate();
    const endDate1 = endMoment1.toDate();

    const d = new Date();
    d.setDate(0); // 0 will result in the last day of the last period
    d.setDate(1); // 1 will result in the first day of the month

    const startMoment2 = new moment(d).startOf("month");
    const endMoment2 = new moment(d).endOf("month");
    const startDate2 = startMoment2.toDate();
    const endDate2 = endMoment2.toDate();

    // const { setCurrentAsset } = useMyContext();
    // setCurrentAsset(null);

    const [playerImpressionsThisPeriod, setPlayerImpressionsThisPeriod] = useState(-1);
    const [playsThisPeriod, setPlaysThisPeriod] = useState(-1);
    // const [orgUniqueViewersThisPeriod, setUniqueViewersThisPeriod] = useState(-1);
    const [watchedMinutesThisPeriod, setWatchedMinutesThisPeriod] = useState(-1);
    const [averageViewingTimeThisPeriod, setAverageViewingTimeThisPeriod] = useState(-1);
    // const [createdAssetsThisPeriod, setCreatedAssetsThisPeriod] = useState(-1);
    // const [publishedAssetsThisPeriod, setPublishedAssetsThisPeriod] = useState(-1);

    const [playerImpressionsLastPeriod, setPlayerImpressionsLastPeriod] = useState(-1);
    const [playsLastPeriod, setPlaysLastPeriod] = useState(-1);
    // const [orgUniqueViewersLastPeriod, setUniqueViewersLastPeriod] = useState(-1);
    const [watchedMinutesLastPeriod, setWatchedMinutesLastPeriod] = useState(-1);
    const [averageViewingTimeLastPeriod, setAverageViewingTimeLastPeriod] = useState(-1);
    // const [createdAssetsLastPeriod, setCreatedAssetsLastPeriod] = useState(-1);
    // const [publishedAssetsLastPeriod, setPublishedAssetsLastPeriod] = useState(-1);

    const [modalDateRangePickerIsOpen, setModalDateRangePickerIsOpen] = useState(false);

    const [startDateThisPeriod, setStartDateThisPeriod] = useState(startDate1);
    const [endDateThisPeriod, setEndDateThisPeriod] = useState(endDate1);
    const [startDateLastPeriod, setStartDateLastPeriod] = useState(startDate2);
    const [endDateLastPeriod, setEndDateLastPeriod] = useState(endDate2);

    const [startISODateThisPeriod, setStartISODateThisPeriod] = useState(getISODate(startDate1));
    const [endISODateThisPeriod, setEndISODateThisPeriod] = useState(getISODate(endDate1));
    const [startISODateLastPeriod, setStartISODateLastPeriod] = useState(getISODate(startDate2));
    const [endISODateLastPeriod, setEndISODateLastPeriod] = useState(getISODate(endDate2));

    // const [selectedVideos, setSelectedVideos] = useState("all-videos");

    // const [showLoader, setShowLoader] = useState(false);

    //const [selectedItems, setSelectedItems] = useState([]);
    /*const chartData = [
        portfolioData,
        ...[schcData, vcitData].filter((d) => selectedItems.includes(d.name)),
    ];*/

    const [watchedMinutesValues, setWatchedMinutesValues] = useState(null);
    // const [uniqueViewersValues, setUniqueViewersValues] = useState(null);
    const [impressionsValues, setImpressionsValues] = useState(null);
    const [playsValues, setPlaysValues] = useState(null);

    // const [createdBy, setCreatedBy] = useState(null);

    const ONE_DAY = 1000 * 60 * 60 * 24;

    const calculateDays = (startDate, endDate) => {
        const delta = endDate - startDate;
        const days = Math.ceil(delta / ONE_DAY);
        return days;
    };

    // Put all start dates and end dates and related stuff into state variables
    const updateDateRanges = (startDate, endDate) => {
        const startDate1 = startDate;
        const endDate1 = endDate;

        // console.log("startDate1", startDate1);
        // console.log("endDate1", endDate1);

        const days = calculateDays(startDate, endDate);
        // console.log("-------------> days", days);
        const milliseconds = days * ONE_DAY;

        // Put an equally long period of time as the last period
        const startDate2 = new Date(startDate1 - milliseconds);
        const endDate2 = new Date(endDate1 - milliseconds);

        // console.log("startDate2", startDate2);
        // console.log("endDate2", endDate2);

        setStartDateThisPeriod(startDate1);
        setEndDateThisPeriod(endDate1);
        setStartISODateThisPeriod(getISODate(startDate1));
        setEndISODateThisPeriod(getISODate(endDate1));

        setStartDateLastPeriod(startDate2);
        setEndDateLastPeriod(endDate2);
        setStartISODateLastPeriod(getISODate(startDate2));
        setEndISODateLastPeriod(getISODate(endDate2));
    };

    // const startMoment = new moment().startOf("month");
    // const endMoment = new moment().endOf("month");
    // const startDate = startMoment.toDate();
    // const endDate = endMoment.toDate();

    //updateDateRanges(startDate, endDate);

    const getKpiThisPeriod = async (name) => {
        // console.log("-----> getKpiThisPeriod: name, organizationId, createdBy", name, organizationId, createdBy);
        return getKpiFromOpenSearch(name, organizationId, groupItemId, createdBy, assetId, eventId, startDateThisPeriod, endDateThisPeriod);
    };
    const getKpiLastPeriod = async (name) => {
        // console.log("-----> getKpiLastPeriod: name, organizationId, createdBy", name, organizationId, createdBy);
        return getKpiFromOpenSearch(name, organizationId, groupItemId, createdBy, assetId, eventId, startDateLastPeriod, endDateLastPeriod);
    };
    // const calculateNumberOfCreatedAssets = (res) => {
    //     if (!res) return -1; // N/A
    //     if (!res.data) return -1; // N/A
    //     // console.log(res.data);
    //     let status = res.status;
    //     if (status === "ok") return -1; // N/A
    //     if (!res.data.data) return -1; // N/A
    //     let data = res.data.data;
    //     // console.log("====> data", data);

    //     let values = Object.values(data);
    //     if (values.length === 0) {
    //         return 0;
    //     }

    //     // console.log("====> values", values);

    //     const reducer = (previousValue, currentValue) => previousValue + currentValue;
    //     const sum = values.reduce(reducer);

    //     return sum;
    // };
    // const calculateNumberOfPublishedAssets = (res) => {
    //     if (!res) return -1; // N/A
    //     if (!res.data) return -1; // N/A
    //     // console.log(res.data);
    //     let status = res.status;
    //     if (status === "ok") return -1; // N/A
    //     if (!res.data.data) return -1; // N/A
    //     let data = res.data.data;
    //     // console.log("====> data", data);

    //     let values = Object.values(data);
    //     if (values.length === 0) {
    //         return 0;
    //     }

    //     // console.log("====> values", values);

    //     const reducer = (previousValue, currentValue) => previousValue + currentValue;
    //     const sum = values.reduce(reducer);

    //     return sum;
    // };

    /*const onChangeSelection = (name) => {
        const newSelectedItems = selectedItems.includes(name)
            ? selectedItems.filter((item) => item !== name)
            : [...selectedItems, name];
        setSelectedItems(newSelectedItems);
    };*/

    useEffect(() => {
        // console.log('FETCH');
        // this period
        const fetchDataOpi = async () => {
            const v = await getKpiThisPeriod("impressionsVOD");
            setPlayerImpressionsThisPeriod(v);
        };
        fetchDataOpi();
        const fetchDataOpAndOwm = async () => {
            const p = await getKpiThisPeriod("playsVOD");
            setPlaysThisPeriod(p);
            const m = await getKpiThisPeriod("watchedMinutesVOD");
            setWatchedMinutesThisPeriod(m);

            // average
            if (p > 0) {
                setAverageViewingTimeThisPeriod(Number(m / p));
            } else if (Number(m) === 0.0 || Number(p) === 0.0) {
                // if plays or watched minutes is 0, just set the average to 0
                setAverageViewingTimeThisPeriod(Number(0.0));
            }
        };
        fetchDataOpAndOwm();
        // const fetchDataOuv = async () => {
        //     const v = await getKpiThisPeriod("orgUniqueViewers");
        //     setUniqueViewersThisPeriod(v);
        // };
        // fetchDataOuv();

        // // created assets this period
        // const fetchDataCatm = async () => {
        //     const resCatm = await getCreatedAssets(organizationId, secret, startISODateThisPeriod, endISODateThisPeriod, createdBy, groupItemId);
        //     const v = calculateNumberOfCreatedAssets(resCatm);
        //     setCreatedAssetsThisPeriod(v);
        // };
        // fetchDataCatm();
        // // published assets this period
        // const fetchDataPatm = async () => {
        //     const resPatm = await getPublishedAssets(organizationId, secret, startISODateThisPeriod, endISODateThisPeriod, createdBy, groupItemId);
        //     const v = calculateNumberOfPublishedAssets(resPatm);
        //     setPublishedAssetsThisPeriod(v);
        // };
        // fetchDataPatm();

        // last period
        const fetchDataOpipm = async () => {
            const v = await getKpiLastPeriod("impressionsVOD");
            setPlayerImpressionsLastPeriod(v);
        };
        fetchDataOpipm();
        const fetchDataOppmAndOwmpm = async () => {
            const p = await getKpiLastPeriod("playsVOD");
            setPlaysLastPeriod(p);
            const m = await getKpiLastPeriod("watchedMinutesVOD");
            setWatchedMinutesLastPeriod(m);

            // average
            if (p > 0) {
                setAverageViewingTimeLastPeriod(Number(m / p));
            } else if (Number(m) === 0.0 || Number(p) === 0.0) {
                // if plays or watched minutes is 0, just set the average to 0
                setAverageViewingTimeLastPeriod(Number(0.0));
            }
        };
        fetchDataOppmAndOwmpm();
        // const fetchDataOuvpm = async () => {
        //     const v = await getKpiLastPeriod("orgUniqueViewers");
        //     setUniqueViewersLastPeriod(v);
        // };
        // fetchDataOuvpm();

        // created assets last period
        // const fetchDataCapm = async () => {
        //     const resCapm = await getCreatedAssets(organizationId, secret, startISODateLastPeriod, endISODateLastPeriod, createdBy);
        //     const v = calculateNumberOfCreatedAssets(resCapm);
        //     setCreatedAssetsLastPeriod(v);
        // };
        // fetchDataCapm();

        // published assets last period
        // const fetchDataPapm = async () => {
        //     const resPapm = await getPublishedAssets(organizationId, secret, startISODateLastPeriod, endISODateLastPeriod, createdBy);
        //     const v = calculateNumberOfPublishedAssets(resPapm);
        //     setPublishedAssetsLastPeriod(v);
        // };
        // fetchDataPapm();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizationId, secret, createdBy, startISODateThisPeriod, endISODateThisPeriod, startISODateLastPeriod, endISODateLastPeriod]);

    useEffect(() => {
        const getKpiData = async (name) => {
            const startDate = startDateLastPeriod;
            const endDate = endDateThisPeriod;

            console.log("organizationId", organizationId);
            console.log("groupItemId", groupItemId);
            // console.log("createdBy", createdBy);
            console.log("assetId", assetId);
            console.log("eventId", eventId);

            const data = await getKpiGraphData(name, organizationId, groupItemId, createdBy, assetId, eventId, startDate, endDate);
            return data;
        };

        const fetchGraphData = async () => {
            const watchedMinutes = await getKpiData("watchedMinutesVOD");
            if (watchedMinutes) {
                setWatchedMinutesValues(watchedMinutes);
            }
            // const uniqueViewers = await getKpiData("uniqueViewersVOD");
            // if (uniqueViewers) {
            //     setUniqueViewersValues(uniqueViewers);
            // }
            const impressions = await getKpiData("impressionsVOD");
            if (impressions) {
                setImpressionsValues(impressions);
            }
            const plays = await getKpiData("playsVOD");
            if (plays) {
                setPlaysValues(plays);
            }
        };
        fetchGraphData();
    }, [organizationId, createdBy, assetId, startDateLastPeriod, endDateThisPeriod, groupItemId]);

    // useEffect(() => {
    //     const runFunction = async () => {
    //         try {
    //             // if all events are fetched all ready, why to do it again? So rather just filter events from latestAssets
    //             // if all events are empty then fetch events
    //             if (latestAssets === null || latestAssets.length === 0) {
    //                 // setShowLoader(true);

    //                 if (userId) {
    //                     let limit = 1000; // how many assets to request from back end at most, these will be filtered
    //                     const resLatest = await getLatestAssets(organizationId, secret, limit, userId);

    //                     // TODO: backend doesn't seem to care about userId, so we filter below.

    //                     // console.log(`LatestAssets res latestAssets: `, resLatest);

    //                     if (resLatest.data.status === "ok") {
    //                         let latest = resLatest.data.assets.filter((asset) => Number(asset.createdBy) === Number(userId));
    //                         console.log(`LatestAssets latest: `, latest);
    //                         let aFewLatest = [];
    //                         for (let i = 0; i < 10 && i < latest.length; i++) {
    //                             aFewLatest.push(latest[i]);
    //                         }

    //                         setLatestAssets(aFewLatest);
    //                     }
    //                 } else {
    //                     let limit = 10; // how many assets to request from back end at most
    //                     const resLatest = await getLatestAssets(organizationId, secret, limit);

    //                     console.log(`LatestAssets res latestAssets: `, resLatest);

    //                     if (resLatest.data.status === "ok") {
    //                         let latest = resLatest.data.assets;
    //                         // console.log(`LatestAssets latest: `, latest);
    //                         setLatestAssets(latest);
    //                     }
    //                 }

    //                 // setShowLoader(false);
    //             }
    //         } catch (error) {
    //             console.log(error);
    //         }
    //     };

    //     organizationId && runFunction();
    // }, [organizationId, secret, userId, latestAssets, setLatestAssets]);

    useEffect(() => {
        const getAssets = async () => {
            try {
                let assets = [];
                let res = null;

                // console.log("HERE: orgId, secret, userId", organizationId, secret, userId);

                // TODO: the suite getLatestAssets doesn't seem to work if we use the userId/createdBy parameter here, so
                // we just don't use it for now, rather we fetch more assets than needed and slice the result array.

                const limit = 10;

                // if (selectedVideos !== "all-videos" && userId) {
                //     res = await getLatestAssets(organizationId, secret, limit * 5, 0, groupItemId);

                //     // console.log("res", res);

                //     if (res && res.data && res.data.status === "ok") {
                //         assets = res.data.assets;
                //         assets = res.data.assets.filter((asset) => Number(asset.createdBy) === Number(userId));
                //         // console.log("getLatestAssets returned " + assets.length + " assets (limit " + limit + ")");
                //     }
                // } else {
                res = await getLatestAssets(organizationId, secret, limit, 0, groupItemId);

                // console.log("res", res);

                if (res && res.data && res.data.status === "ok") {
                    assets = res.data.assets;
                    console.log("getLatestAssets returned " + assets.length + " assets (limit " + limit + ")");
                }
                // }

                // show only 10 of the ones we got from the back end
                assets = assets.slice(0, limit);

                // if (userId) {
                //     const limit = 256;

                //     // console.log("have user id", userId);

                //     // Currently Suite's getLatestAssets doesn't seem to recognize the userId parameter
                //     res = await getLatestAssets(organizationId, secret, limit, userId);

                //     // console.log("res", res);

                //     if (res && res.data && res.data.status === "ok") {
                //         assets = res.data.assets.filter((asset) => Number(asset.createdBy) === Number(userId));
                //         console.log("getLatestAssets returned " + assets.length + " assets (limit " + limit + ")");
                //         // grab the 10 first assets from the filtered result
                //         assets = assets.slice(0, 10);
                //     }
                // } else {
                //     const limit = 10;

                //     res = await getLatestAssets(organizationId, secret, limit);

                //     // console.log("res", res);

                //     if (res && res.data && res.data.status === "ok") {
                //         assets = res.data.assets;
                //         console.log("getLatestAssets returned " + assets.length + " assets (limit " + limit + ")");
                //     }
                // }

                setLatestAssets(assets);
            } catch (error) {
                console.log(error);
            }
        };
        organizationId && getAssets();
    }, [
        organizationId,
        secret,
        userId,
        // selectedVideos,
        groupItemId,
    ]);

    useEffect(() => {
        const getAssets = async () => {
            let array = [];

            // console.log("popularAssetIds", popularAssetIds);

            for (let i = 0; i < popularAssetIds.length; i++) {
                let assetId = popularAssetIds[i];

                const res = await getSingleAsset(organizationId, secret, assetId);

                if (res && res.data && res.data.status === "ok") {
                    let asset = res.data;
                    array.push(asset);
                    // console.log("i " + i + " OK   (assetId)" + assetId);
                } else {
                    // console.log("i " + i + " FAIL (assetId)" + assetId);
                }

                // grab only 10 of the most popular assets (we might have more popularAssetIds than 10)
                if (array.length >= 10) {
                    break;
                }
            }

            // grab only 10 of the most popular assets (we might have more popularAssetIds than 10)
            // array = array.slice(0, 10);

            setPopularAssets(array);

            // let promises = [];

            // for (let i = 0; i < popularAssetIds.length; i++) {
            //     let assetId = popularAssetIds[i];

            //     // no await here so this should return a promise
            //     const res = getSingleAsset(organizationId, secret, assetId);
            //     promises.push(res);
            // }

            // Promise.all(promises).then((values) => {
            //     // should have an array of 10 res

            //     for (let j = 0; j < values.length; j++) {
            //         let res = values[j];
            //         if (res && res.data && res.data.status === "ok") {
            //             let asset = res.data;
            //             array.push(asset);
            //         }
            //     }
            //     setPopularAssets(array);
            // });
        };
        if (popularAssetIds && popularAssetIds.length > 0) {
            organizationId && getAssets();
        }
    }, [organizationId, secret, popularAssetIds]);

    useEffect(() => {
        const getPopularAssetIds = async () => {
            let createdBy = null;

            // if (selectedVideos !== "all-videos" && userId) {
            //     createdBy = userId;
            // }

            // we request more than 10 assets here, because when we ask for the assets from suite,
            // there's some issues for some, so we need a few extra

            //const groupItemId = null;
            let result = await getKpiValuesFromOpenSearchForPopularAssets("totalPlaysVOD", organizationId, groupItemId, createdBy, 20);
            //console.log('RESULT', result);
            let assetIds = result.map((r) => {
                return r.assetId;
            });
            setPopularAssetIds(assetIds);
        };
        getPopularAssetIds();
    }, [
        organizationId,
        userId,
        //selectedVideos,
        groupItemId,
    ]);

    useEffect(() => {
        const getGroupItems = async () => {
            let result = await getCategories(organizationId, "pages");
            if (typeof groupItemId === "undefined" || groupItemId === null || groupItemId === "") {
                window.location.href = window.location.href + "&groupItemId=" + result[0]?.id;
            } else {
                //console.log('RESULTS: ', result);
                setAllGroupItems(result);
            }
        };
        getGroupItems();
    }, [organizationId, groupItemId]);

    const selectDateRangeButtonHandler = () => {
        setModalDateRangePickerIsOpen(true);
    };

    const closeModalHandler = () => {
        setModalDateRangePickerIsOpen(false);
    };

    const saveModalHandler = (startDate, endDate) => {
        // console.log("saveModalHandler startDate, endDate", startDate, endDate);

        updateDateRanges(startDate, endDate);

        setModalDateRangePickerIsOpen(false);
    };

    // const showAllVideosButtonHandler = () => {
    //     setCreatedBy(null);
    //     setShowAllVideos(true);
    // };
    // const showOnlyMyVideosButtonHandler = () => {
    //     setCreatedBy(userId);
    //     setShowAllVideos(false);
    // };

    const selectionChangeHandler = (e) => {
        // console.log("selected", e);
        if (groupItemId) {
            window.location.href = window.location.href.replace(groupItemId, e.id);
        } else {
            window.location.href = window.location.href + "&groupItemId=" + e.id;
        }
    };

    // const { searchAssets, setSearchAssets } = useMyContext();
    // const { searchAssetsString, setSearchAssetsString } = useMyContext();

    const onSearchClick = (e) => {
        // console.log("CLICK: search string is '" + searchString + "'");

        // TODO: what about search string ???

        if (searchAssetsString && searchAssetsString.length > 0) {
            history.push(`/analytics/vod/dashboard/search`);
        }
    };

    const onSearchStringChange = (e) => {
        // console.log("SEARCH STRING CHANGE, e is ", e);

        // setSearchString(e.target.value);
        setSearchAssetsString(e.target.value);

        // if (e.key === "Enter") {
        //     console.log("GOT ENTER");
        // }
    };

    const onSearchStringKeyDown = (e) => {
        // console.log("got keydown", e);

        if (e.key === "Enter") {
            if (searchAssetsString && searchAssetsString.length > 0) {
                history.push(`/analytics/vod/dashboard/search`);
            }
        }
    };

    return (
        <div className={classes.view}>
            {/* <h1>Video Analytics</h1> */}
            <div className={classes.actions}>
                <div className={classes.groupItemTitle}>
                    Video page: {allGroupItems.find((groupItem) => Number(groupItem.id) === Number(groupItemId))?.title}
                </div>
                {/* {userId > 0 && !showAllVideos && <Button color="#0a7aad" text="Show analytics for all videos" onClick={showAllVideosButtonHandler} />}
                    {userId > 0 && showAllVideos && <Button color="#0a7aad" text="Show analytics for my videos" onClick={showOnlyMyVideosButtonHandler} />} */}
                <div>
                    <Button color="#0a7aad" text="Select Date Range" onClick={selectDateRangeButtonHandler} />
                    &nbsp;
                    <Select
                        options={allGroupItems}
                        className={classes.select}
                        getOptionValue={(e) => e.id}
                        getOptionLabel={(e) => e.title}
                        value={allGroupItems.find((groupItem) => Number(groupItem.id) === Number(groupItemId))}
                        onChange={selectionChangeHandler}
                    />
                    {/*&nbsp;
                    {userId > 0 && (
                        // <Select className={classes.select} options={options} value={"all-videos"} defaultValue={"all-videos"} onChange={selectionChangeHandler} />
                        <MySelect
                            options={videoOptions}
                            value={selectedVideos}
                            defaultValue={'all-videos'}
                            onChange={selectionChangeHandler}
                        />
                    )}*/}
                    &nbsp;
                    {/* <SearchForAssets organizationId={organizationId} secret={secret} userId={userId} /> */}
                    <input
                        type="text"
                        placeholder="Search for assets"
                        className={classes.input}
                        onChange={onSearchStringChange}
                        onKeyDown={onSearchStringKeyDown}
                    />
                    &nbsp;
                    <FontAwesomeIcon icon="search" size="1x" style={{ backgroundColor: "#eceff1" }} onClick={onSearchClick} />
                </div>
            </div>

            {modalDateRangePickerIsOpen && (
                <ModalDateRangePicker startDate={startDateThisPeriod} endDate={endDateThisPeriod} onOk={saveModalHandler} onCancel={closeModalHandler} />
            )}
            {modalDateRangePickerIsOpen && <Backdrop onCancel={closeModalHandler} />}

            <SelectedDateRangeHeading startDate={startDateThisPeriod} endDate={endDateThisPeriod} periodName={"Current period"} />

            <div className={classes.overviewWrapper14}>
                <ValueCard value={formatAssetNumberValue(playerImpressionsThisPeriod)} title={"Player Impressions"} />
                <ValueCard value={formatAssetNumberValue(playsThisPeriod)} title={"Plays"} />
                {/* <ValueCard value={orgUniqueViewersThisPeriod} title={"Unique Viewers"} /> */}
                <ValueCard value={formatAssetDuration(watchedMinutesThisPeriod)} title={"Time Viewed"} />
                <ValueCard value={formatAssetDuration(averageViewingTimeThisPeriod)} title={"Average viewing time"} />
                {/* <ValueCard value={formatAssetNumberValue(createdAssetsThisPeriod)} title={"Created Assets"} />
                <ValueCard value={formatAssetNumberValue(publishedAssetsThisPeriod)} title={"Published Assets"} /> */}
            </div>

            <SelectedDateRangeHeading startDate={startDateLastPeriod} endDate={endDateLastPeriod} periodName={"Previous period"} />

            <div className={classes.overviewWrapper14}>
                <ValueCard value={formatAssetNumberValue(playerImpressionsLastPeriod)} title={"Player Impressions"} />
                <ValueCard value={formatAssetNumberValue(playsLastPeriod)} title={"Plays"} />
                {/* <ValueCard value={orgUniqueViewersLastPeriod} title={"Unique Viewers"} /> */}
                <ValueCard value={formatAssetDuration(watchedMinutesLastPeriod)} title={"Time Viewed"} />
                <ValueCard value={formatAssetDuration(averageViewingTimeLastPeriod)} title={"Average viewing time"} />
                {/* <ValueCard value={formatAssetNumberValue(createdAssetsLastPeriod)} title={"Created Assets"} />
                <ValueCard value={formatAssetNumberValue(publishedAssetsLastPeriod)} title={"Published Assets"} /> */}
            </div>

            <h2>Plays</h2>
            <div className={classes.overviewWrapper3}>
                <div className={classes.resultItem3}>
                    <GenericChart
                        organizationId={organizationId}
                        createdBy={createdBy}
                        chartType={"playsVOD"}
                        data={[
                            {
                                name: "impressionsVOD",
                                values: impressionsValues,
                            },
                            {
                                name: "playsVOD",
                                values: playsValues,
                            },
                            {
                                name: "watchedMinutesVOD",
                                values: watchedMinutesValues,
                            },
                        ]}
                        startDate={startDateLastPeriod}
                        endDate={endDateThisPeriod}
                    />
                </div>
            </div>

            <h2>Impressions</h2>
            <div className={classes.overviewWrapper3}>
                <div className={classes.resultItem3}>
                    <GenericChart
                        organizationId={organizationId}
                        createdBy={createdBy}
                        chartType={"impressionsVOD"}
                        data={[
                            {
                                name: "impressionsVOD",
                                values: impressionsValues,
                            },
                            {
                                name: "playsVOD",
                                values: playsValues,
                            },
                            {
                                name: "watchedMinutesVOD",
                                values: watchedMinutesValues,
                            },
                        ]}
                        startDate={startDateLastPeriod}
                        endDate={endDateThisPeriod}
                    />
                </div>
            </div>

            <h2>Watched minutes</h2>
            <div className={classes.overviewWrapper3}>
                <div className={classes.resultItem3}>
                    <GenericChart
                        organizationId={organizationId}
                        createdBy={createdBy}
                        chartType={"watchedMinutesVOD"}
                        data={[
                            {
                                name: "impressionsVOD",
                                values: impressionsValues,
                            },
                            {
                                name: "playsVOD",
                                values: playsValues,
                            },
                            {
                                name: "watchedMinutesVOD",
                                values: watchedMinutesValues,
                            },
                        ]}
                        startDate={startDateLastPeriod}
                        endDate={endDateThisPeriod}
                    />
                </div>
            </div>

            {/* <h2>Multiline Chart</h2>

            <div className={classes.overviewWrapper3}>
                <div className={classes.resultItem3}>
                    <Legend data={legendData} selectedItems={selectedItems} onChange={onChangeSelection} />
                    <MultilineChart organizationId={organizationId} data={chartData} dimensions={dimensions} />
                </div>
            </div> */}

            {/* <LatestAssets organizationId={props.organizationId} secret={props.secret} userId={props.userId} /> */}
            {/* <div className={classes.Assets_dashboard_title}>
                {showLoader && (
                    <div className={classes.Assets_loader}>
                        {" "}
                        <Loader type="Oval" color="#394855" height={300} width={300} />
                    </div>
                )}
            </div>{" "} */}
            {/* <div className={classes.Assets_dashboard_title}>
                {" "}
                {latestAssets.length > 0 && (
                    <AssetsItem
                        assets={latestAssets}
                        // .filter((event) => event.startTime < startDay && event.startTime > monthAgo)
                        // .sort((a, b) => b.startTime - a.startTime)}
                        heading={"Latest and most popular video assets"}
                        organizationId={props.organizationId}
                        showNoImage={true}
                    />
                )}{" "}
            </div>{" "} */}

            <LatestAndMostPopularAssets
                organizationId={props.organizationId}
                secret={props.secret}
                userId={props.userId}
                groupItemId={props.groupItemId}
                latestAssets={latestAssets}
                popularAssets={popularAssets}
                // showLoader={showLoader}
            />
        </div>
    );
};

export default VODAnalyticsDashboard;
