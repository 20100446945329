import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";

import HomeLink from "./HomeLink";
import AssetsTable from "./AssetsTable";

import { useMyContext } from "../../contexts/StateHolder";
import { getSearchAssets } from "../../scripts/dataHandlers";

import classes from "./VODAnalyticsSearch.module.css";

const VODAnalyticsSearch = (props) => {
    const organizationId = props.organizationId;
    const userId = props.userId;
    const groupItemId = props.groupItemId;

    const { searchAssetsString } = useMyContext();

    const [foundAssets, setFoundAssets] = useState([]);
    const [showLoader, setShowLoader] = useState(true);

    console.log("VODAnalyticsSearch, orgId, userId, groupItemId", organizationId, userId, groupItemId);
    console.log("VODAnalyticsSearch, searchAssetsString", searchAssetsString);

    // const runFunction = useCallback(async () => {
    //     try {
    //         //if (searchAssets.length === 0) {
    //         // setShowLoader(true);
    //         const resSearch = await getSearchAssets(organizationId, searchString, userId);

    //         // TODO: backend doesn't seem to care about userId, so we filter below.

    //         // console.log(`res search: `, resSearch);

    //         if (resSearch.data.status === "ok") {
    //             let assets = [];
    //             if (userId) {
    //                 assets = resSearch.data.assets.filter((asset) => Number(asset.createdBy) === Number(userId));
    //             } else {
    //                 assets = resSearch.data.assets;
    //             }
    //             // console.log("search for '" + search + "', hits " + assets.length);
    //             setSearchAssets(assets);
    //         }
    //         // setShowLoader(false);
    //         //}
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }, [organizationId, userId, searchString, setSearchAssets]);

    useEffect(() => {
        const doSearch = async () => {
            const resSearch = await getSearchAssets(organizationId, searchAssetsString, userId, groupItemId);

            // console.log("resSearch", resSearch);

            if (resSearch) {
                setShowLoader(false);
            }

            if (resSearch && resSearch.data.status === "ok") {
                let assets = [];
                if (userId) {
                    assets = resSearch.data.assets.filter((asset) => Number(asset.createdBy) === Number(userId));
                } else {
                    assets = resSearch.data.assets;
                }
                setFoundAssets(assets);
            }
        };

        //organizationId && runFunction();

        doSearch();
    }, [organizationId, userId, groupItemId, searchAssetsString]);

    return (
        <div className={classes.view}>
            <div>
                {showLoader && (
                    <div className={classes.Assets_loader}>
                        <Loader type="Oval" color="#394855" height={300} width={300} />
                    </div>
                )}
                {!showLoader && (
                    <div>
                        <HomeLink />

                        {/* {foundAssets && foundAssets.length > 0 ? foundAssets.map((asset) => <p>{asset.id}</p>) : <p>No results</p>} */}
                        {foundAssets && foundAssets.length > 0 ? (
                            <AssetsTable assets={foundAssets} organizationId={organizationId} userId={userId} groupItemId={groupItemId} />
                        ) : (
                            <p>No results</p>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default VODAnalyticsSearch;
