//
// NOTE: This file, and openSearchKpis.js should be edited in a way that it can be used with
// all analytics react apps, i.e.
// cdn-analytics-react-app,
// event-analytics-react-app,
// vod-analytics-react-app,
// video-page-analytics-react-app,
// and so on.
//
// If these opensearch related files are edited in any of the above, the files should be
// copied also to the other projects and tested. Testing can be done by just running the app
// locally and checking that all the KPIs show up in the user interface, that indicates that
// the changes are OK.
//

import axios from "axios";
import moment from "moment";

import settings from "../config_settings.json";
import { getKpiName, getTotalKpiName } from "./openSearchKpis";

// curl -X POST 'https://suiterc2.icareus.com/api/analytics/events?action=doOpenSearchQuery&urlPath=/kpi-kristian/_search' -H 'Content-Type: application/json' -d '{  "query": {    "match_all": {}  }}'

export const formatOpenSearchQuery = (
    kpiName,
    orgId,
    groupItemId,
    createdBy,
    assetId,
    eventId,
    timestampGte, // unix timestamp, milliseconds
    timestampLte, // unix timestamp, milliseconds
    size = 1
) => {
    let mustArray = [];

    // console.log("formatOpenSearchQuery kpiName " + kpiName + ", timestampGte " + timestampGte + ", timestampLte " + timestampLte);

    // startDate Mon Oct 18 2021 00:00:00 GMT+0300 (Eastern European Summer Time) EventOverview.js:40
    // unix startDate 1634504400000 EventOverview.js:41
    // endDate Mon Oct 18 2021 23:59:59 GMT+0300 (Eastern European Summer Time) EventOverview.js:42
    // unix endDate 1634590799999

    if (kpiName !== undefined && kpiName !== null) {
        const nameTerm = {
            term: {
                name: kpiName,
            },
        };
        mustArray.push(nameTerm);
    }

    if (orgId !== undefined && orgId !== null) {
        const organizationTerm = {
            term: {
                orgId: orgId,
            },
        };
        mustArray.push(organizationTerm);
    }

    // Added for video-page-analytics
    if (groupItemId !== undefined && groupItemId !== null) {
        const groupItemTerm = {
            term: {
                groupItemId: groupItemId,
            },
        };
        mustArray.push(groupItemTerm);
    }

    if (assetId !== undefined && assetId !== null) {
        const assetTerm = {
            term: {
                assetId: assetId,
            },
        };
        mustArray.push(assetTerm);
    } else if (eventId !== undefined && eventId !== null) {
        const eventTerm = {
            term: {
                eventId: eventId,
            },
        };
        mustArray.push(eventTerm);
    } else {
        // no asset or event id
        if (createdBy !== undefined && createdBy !== null) {
            const creatorTerm = {
                term: {
                    createdBy: createdBy,
                },
            };
            mustArray.push(creatorTerm);
        }
    }

    if (timestampGte !== undefined && timestampLte !== undefined && timestampGte !== null && timestampLte !== null) {
        const timestampRange = {
            range: {
                timestamp: {
                    gte: timestampGte,
                    lte: timestampLte,
                },
            },
        };
        mustArray.push(timestampRange);
    }

    let query = {
        bool: {
            must: mustArray,
        },
    };

    // console.log("query", query);
    // console.log("query json", JSON.stringify(query));

    const data = {
        size: size,

        // https://www.elastic.co/guide/en/elasticsearch/reference/7.0/search-request-sort.html

        sort: [{ timestamp: "asc" }],

        _source: ["timestamp", "value"], // WHAT FIELDS ARE RETURNED
        query: query,
        // aggs: aggs,
    };

    // Logs out query we are doing
    // console.log("formatOpenSearchQuery: OPENSEARCH QUERY ====>", kpiName, JSON.stringify(data));

    return data;
};

export const makeOpenSearchQuery = (queryData) => {
    const openSearchServer = settings.openSearch.server;
    const openSearchPath = settings.openSearch.path;

    const url = openSearchServer;

    const config = {
        headers: {
            "Content-type": "application/json",
        },
        params: {
            action: "doOpenSearchQuery",
            urlPath: openSearchPath,
        },
    };

    // Logs out query we are doing
    console.log("makeOpenSearchQuery: OPENSEARCH QUERY ====>", JSON.stringify(queryData));

    return axios.post(url, queryData, config);
};

// array of hits is returned
export const getHitsFromOpenSearchResponse = (res) => {
    if (!res) {
        console.log("no res");
        return null;
    }
    if (!res.data) {
        console.log("no res.data");
        return null;
    }

    // Tunneling, we don't get message "success" and status "ok"
    if (res.data.hits && res.data.hits.hits) {
        return res.data.hits.hits;
    }
    // Suite returns in data: { data, message, status }

    // console.log("res", res);
    // console.log("res.data", res.data);
    // console.log("res.data.message", res.data.message);
    // console.log("res.data.status", res.data.status);
    // console.log("res.data.data", res.data.data);
    // console.log("res.data.data.hits", res.data.data.hits);
    // console.log("res.data.data.hits.hits", res.data.data.hits.hits);

    if (res.data.data && res.data.message === "success" && res.data.status === "ok") {
        // console.log("Got here!");

        if (res.data.data.hits && res.data.data.hits.hits) {
            // console.log("Got here!!!!");
            return res.data.data.hits.hits;
        }
    }

    return null;
};

const getKpiGraphDataMinutePrecision = async (kpiName, organizationId, groupItemId, createdBy, assetId, eventId, startDate, endDate) => {
    try {
        // const delta = endDate - startDate;
        const ONE_DAY_IN_MILLIS = 1000 * 60 * 60 * 24;

        const days = getDays(startDate, endDate);

        let size = 24 * 60; // max number of hits: minutes in a day
        size *= days;

        const query = formatOpenSearchQuery(kpiName, organizationId, groupItemId, createdBy, assetId, eventId, startDate, endDate, size);
        const res = await makeOpenSearchQuery(query);
        const hits = getHitsFromOpenSearchResponse(res);
        //if (hits && hits.length > 0 && hits[0]._source) {
        if (hits) {
            // if (hits.length <= 0) {
            //     // empty array
            //     console.log("getKpiGraphDataMinutePrecision: empty array for kpi", kpiName);
            //     return null;
            // }

            let unixMillisIncrement = 1000 * 60; // 60000 ms is one minute

            let startUnixMillis = new Date(startDate).getTime();
            //let endUnixMillis = new Date(endDate).getTime();
            let endUnixMillis = startUnixMillis + ONE_DAY_IN_MILLIS * days;

            // let xValues = [];
            // let yValues = [];
            let returnArray = [];

            // TODO: we use < here, but <= might also be right, this might have to do with time zone stuff
            for (let t = startUnixMillis; t < endUnixMillis; t += unixMillisIncrement) {
                //console.log("t", t);
                // xValues.push(t);
                // look what the value is for this timestamp
                let found = hits.find((hit) => hit._source.timestamp === t); // TODO: was ==, test

                let val = 0;
                if (found) {
                    //console.log("FOUND", found);
                    val = found._source.value;
                }
                // yValues.push(val);
                let obj = {};
                obj[t] = val;

                returnArray.push(obj);
            }

            // const returnObject = {
            //     xValues: xValues, //[startUnixMillis, endUnixMillis],
            //     yValues: yValues, //[10, 30],
            // };

            // console.log("getKpiGraphDataMinutePrecision returnObject", returnObject);

            // return returnObject;
            return returnArray;
        } else {
            console.log("get Kpi GraphData MinutePrecision: no hits for kpi", kpiName);
            return null; // -1 == N/A
        }
    } catch (error) {
        console.log(error);
    }
};

const getKpiGraphDataDayPrecision = async (kpiName, organizationId, groupItemId, createdBy, assetId, eventId, startDate, endDate) => {
    try {
        const delta = endDate - startDate;
        const ONE_DAY_IN_MILLIS = 1000 * 60 * 60 * 24;
        const days = Math.ceil(delta / ONE_DAY_IN_MILLIS);
        console.log("getKpiGraphData =====> days", days);

        let size = 1;

        if (delta < ONE_DAY_IN_MILLIS) {
            size = 24 * 60; // max number of hits: minutes in a day
        } else {
            size = days;
        }

        const query = formatOpenSearchQuery(kpiName, organizationId, groupItemId, createdBy, assetId, eventId, startDate, endDate, size);
        const res = await makeOpenSearchQuery(query);
        const hits = getHitsFromOpenSearchResponse(res);
        //if (hits && hits.length > 0 && hits[0]._source) {
        if (hits) {
            console.log("getKpiGraphDataDayPrecision hits", hits);

            let startUnixMillis = new Date(startDate).getTime();
            //let endUnixMillis = new Date(endDate).getTime();
            let endUnixMillis = startUnixMillis + size * ONE_DAY_IN_MILLIS;

            let returnArray = [];

            console.log("===> startDate", startDate);
            console.log("===> endDate", endDate);
            console.log("===> startUnixMillis", startUnixMillis);
            console.log("===> endUnixMillis", endUnixMillis);

            // 2022-04-26: the following code breaks when we move from winter to summer time

            for (let t = startUnixMillis; t < endUnixMillis; t += ONE_DAY_IN_MILLIS) {
                let val = 0;
                for (let i = 0; i < hits.length; i++) {
                    let hts = hits[i]._source.timestamp;
                    if (hts >= t && hts < t + ONE_DAY_IN_MILLIS) {
                        //console.log("t, hts, d", t, hts, t - hts);
                        val = hits[i]._source.value;
                        break;
                    }
                }
                let obj = {};
                let d = moment(new Date(t)).format("DD.MM.YYYY");
                obj[d] = val;

                returnArray.push(obj);
            }

            // console.log("getKpiGraphData hits", hits);

            console.log("getKpiGraphData returnArray for kpiName", kpiName, returnArray);

            return returnArray;
        } else {
            console.log("get Kpi GraphData DayPrecision: no hits for kpi", kpiName);
            return null; // -1 == N/A
        }
    } catch (error) {
        console.log(error);
    }
};

const getDays = (startDate, endDate) => {
    const delta = Math.abs(endDate - startDate);
    const ONE_DAY_IN_MILLIS = 1000 * 60 * 60 * 24;
    const days = Math.ceil(delta / ONE_DAY_IN_MILLIS);
    return days;
};

// Called from elsewhere
export const getKpiGraphData = async (kpiNickName, organizationId, groupItemId, createdBy, assetId, eventId, startDate, endDate) => {
    // console.log("getKpiGraphData: organizationId, groupItemId, createdBy, assetId", organizationId, groupItemId, createdBy, assetId);

    const days = getDays(startDate, endDate);
    const minutePrecision = days <= 2;
    const kpiName = getKpiName(kpiNickName, minutePrecision, organizationId, groupItemId, createdBy, assetId, eventId);

    if (kpiName === "") {
        console.log("get Kpi GraphData: KPI '" + kpiNickName + "' not implemented!");
        return null;
    }

    if (minutePrecision) {
        return getKpiGraphDataMinutePrecision(kpiName, organizationId, groupItemId, createdBy, assetId, eventId, startDate, endDate);
    } else {
        return getKpiGraphDataDayPrecision(kpiName, organizationId, groupItemId, createdBy, assetId, eventId, startDate, endDate);
    }
};

export const getTotalKpiFromOpenSearch = async (kpiNickName, organizationId, groupItemId, createdBy, assetId, eventId, startDate, endDate) => {
    console.log("getTotalKpiFromOpenSearch", kpiNickName);

    // const days = getDays(startDate, endDate);

    // First check for totals
    let kpiName = getTotalKpiName(kpiNickName, false, organizationId, groupItemId, createdBy, assetId, eventId);

    if (kpiName === "") {
        console.error("getTotalKpiFromOpenSearch: KPI '" + kpiNickName + "' not implemented!");
        return -2;
    }

    try {
        const query = formatOpenSearchQuery(
            kpiName,
            organizationId,
            groupItemId,
            createdBy,
            assetId,
            eventId,
            undefined, // undefined here because totals don't have any time range
            undefined, // undefined here because totals don't have any time range
            1
        );

        const res = await makeOpenSearchQuery(query);

        const hits = getHitsFromOpenSearchResponse(res);
        if (hits.length <= 0) {
            console.log("getTotalKpiFromOpenSearch: total: no hits for kpi", kpiName);
            return 0;
        }

        // console.log("OPENSEARCH RESULT ===>", kpiName, JSON.stringify(hits));

        // const values = hits.map((hit) => (hit._source ? Number(hit._source.value) : 0));

        // if (hits) {
        //     console.log("get Kpi From OpenSearch: kpiNickName, kpiName, days, hits, values", kpiNickName, kpiName, days, hits, values);
        // }

        if (hits && hits.length > 0 && hits[0]._source) {
            let value = Math.round(10 * hits[0]._source.value) / 10.0;
            console.log("getTotalKpiFromOpenSearch: ", kpiNickName, kpiName, value);

            return value;
        } else {
            console.log("getTotalKpiFromOpenSearch: no hits for kpi", kpiName);
            return -1; // -1 == N/A
        }
    } catch (error) {
        console.log(error);
    }
};

export const getKpiFromOpenSearch = async (kpiNickName, organizationId, groupItemId, createdBy, assetId, eventId, startDate, endDate) => {
    // console.log("get Kpi From OpenSearch", kpiNickName);

    const days = getDays(startDate, endDate);

    try {
        // Other than totals
        // Never use minute precision for these as we are not drawing a graph

        const minutePrecision = false; //days <= 2;

        let kpiName = getKpiName(kpiNickName, minutePrecision, organizationId, groupItemId, createdBy, assetId, eventId);

        console.log("-----------> get Kpi From OpenSearch, kpiNickName, kpiName", kpiNickName, kpiName);

        if (kpiName === "") {
            console.error("get Kpi From OpenSearch: KPI '" + kpiNickName + "' not implemented!");
            return -2;
        }

        let makeAddition = true;

        if (kpiName === "") {
            console.error("get Kpi From OpenSearch: KPI '" + kpiNickName + "' not implemented!");
            return -2;
        }

        const query = formatOpenSearchQuery(kpiName, organizationId, groupItemId, createdBy, assetId, eventId, startDate, endDate, days);
        const res = await makeOpenSearchQuery(query);

        const hits = getHitsFromOpenSearchResponse(res);
        //if (hits && hits.length > 0 && hits[0]._source) {
        if (hits) {
            if (hits.length <= 0) {
                // empty array
                console.log("get Kpi From OpenSearch: empty array for kpi", kpiName);
                return 0;
            }
            const values = hits.map((hit) => (hit._source ? Number(hit._source.value) : 0));
            console.log("get Kpi From OpenSearch: kpiNickName, kpiName, days, hits, values", kpiNickName, kpiName, days, hits, values);
            if (makeAddition) {
                const reducer = (previousValue, currentValue) => previousValue + currentValue;
                const sum = values.reduce(reducer);
                return Math.round(10 * sum) / 10.0;
            } else {
                let val = values[0];
                let retVal = Math.round(val * 10.0) / 10.0;
                return retVal;
            }
        } else {
            console.log("get Kpi From OpenSearch: no hits for kpi", kpiName);
            return -1; // -1 == N/A
        }
    } catch (error) {
        console.log(error);
    }
};

// 2022-04-04: New functions to get multiple KPI values in one call

// Asset specific
export const formatMultipleAssetsOpenSearchQuery = (
    kpiName,
    orgId,
    groupItemId,
    createdBy,
    assetIds, // MULTIPLE ASSET IDS
    timestampGte, // unix timestamp, milliseconds
    timestampLte, // unix timestamp, milliseconds
    size = 1
) => {
    let mustArray = [];

    // startDate Mon Oct 18 2021 00:00:00 GMT+0300 (Eastern European Summer Time) EventOverview.js:40
    // unix startDate 1634504400000 EventOverview.js:41
    // endDate Mon Oct 18 2021 23:59:59 GMT+0300 (Eastern European Summer Time) EventOverview.js:42
    // unix endDate 1634590799999

    if (kpiName !== undefined && kpiName !== null) {
        const nameTerm = {
            term: {
                name: kpiName,
            },
        };
        mustArray.push(nameTerm);
    }

    // Added for video-page-analytics
    if (groupItemId !== undefined && groupItemId !== null) {
        const groupItemTerm = {
            term: {
                groupItemId: groupItemId,
            },
        };
        mustArray.push(groupItemTerm);
    }

    if (orgId !== undefined && orgId !== null) {
        const organizationTerm = {
            term: {
                orgId: orgId,
            },
        };
        mustArray.push(organizationTerm);
    }

    // https://www.elastic.co/guide/en/elasticsearch/reference/current/query-dsl-terms-query.html

    if (assetIds !== undefined && assetIds !== null) {
        const assetsTerm = {
            terms: {
                assetId: assetIds,
            },
        };
        mustArray.push(assetsTerm);
    }

    // https://www.elastic.co/guide/en/elasticsearch/reference/current/search-your-data.html

    // if (assetId !== undefined && assetId !== null) {
    //     const assetTerm = {
    //         term: {
    //             assetId: assetId,
    //         },
    //     };
    //     mustArray.push(assetTerm);
    // } else if (eventId !== undefined && eventId !== null) {
    //     const eventTerm = {
    //         term: {
    //             eventId: eventId,
    //         },
    //     };
    //     mustArray.push(eventTerm);
    // } else {
    //     // no asset or event id
    //     if (createdBy !== undefined && createdBy !== null) {
    //         const creatorTerm = {
    //             term: {
    //                 createdBy: createdBy,
    //             },
    //         };
    //         mustArray.push(creatorTerm);
    //     }
    // }

    // if (timestampGte !== undefined && timestampLte !== undefined && timestampGte !== null && timestampLte !== null) {
    //     const timestampRange = {
    //         range: {
    //             timestamp: {
    //                 gte: timestampGte,
    //                 lte: timestampLte,
    //             },
    //         },
    //     };
    //     mustArray.push(timestampRange);
    // }

    let query = {
        bool: {
            must: mustArray,
        },
    };

    // console.log("query", query);
    // console.log("query json", JSON.stringify(query));

    const data = {
        size: size,

        // https://www.elastic.co/guide/en/elasticsearch/reference/7.0/search-request-sort.html

        sort: [{ timestamp: "asc" }],

        // TODO: add groupItemId ?
        _source: ["timestamp", "value", "name", "id", "assetId", "createdBy"], // WHAT FIELDS ARE RETURNED
        query: query,
        // aggs: aggs,
    };

    // Logs out query we are doing
    // console.log("formatMultipleAssetsOpenSearchQuery: OPENSEARCH QUERY ====>", kpiName, JSON.stringify(data));

    return data;
};

// Called from elsewhere
export const getKpiValuesFromOpenSearch = async (kpiNickName, organizationId, groupItemId, assetIds) => {
    //}, createdBy, assetId, eventId, startDate, endDate) => {
    // console.log("get Kpi values From OpenSearch", kpiNickName);

    // const days = getDays(startDate, endDate);

    let createdBy = null;
    let assetId = assetIds[0]; // for getKpiName
    let eventId = null;
    let startDate = null;
    let endDate = null;
    // let days = null;

    try {
        // Other than totals
        // Never use minute precision for these as we are not drawing a graph

        const minutePrecision = false; //days <= 2;

        let kpiName = getKpiName(kpiNickName, minutePrecision, organizationId, groupItemId, createdBy, assetId, eventId);

        if (kpiName === "") {
            console.error("get Kpi values from OpenSearch: KPI '" + kpiNickName + "' not implemented!");
            return [];
        }

        const query = formatMultipleAssetsOpenSearchQuery(kpiName, organizationId, groupItemId, createdBy, assetIds, startDate, endDate, assetIds.length);

        // console.log("-----> NEW QUERY", JSON.stringify(query));

        // console.log("OPENSEARCH QUERY ====>", kpiName, JSON.stringify(data));

        const res = await makeOpenSearchQuery(query);

        const hits = getHitsFromOpenSearchResponse(res);
        //if (hits && hits.length > 0 && hits[0]._source) {
        if (hits) {
            if (hits.length <= 0) {
                // empty array
                console.log("get Kpi values from OpenSearch: empty array for kpi", kpiName);
                return [];
            }

            // console.log("----------> HITS", hits);

            let returnArray = hits.map((hit) => {
                return hit._source ? { assetId: hit._source.assetId, value: hit._source.value } : null;
            });

            // const values = hits.map((hit) => (hit._source ? Number(hit._source.value) : 0));
            // console.log("get Kpi values from OpenSearch: kpiNickName, kpiName, days, hits, values", kpiNickName, kpiName, days, hits, values);
            // if (makeAddition) {
            //     const reducer = (previousValue, currentValue) => previousValue + currentValue;
            //     const sum = values.reduce(reducer);
            //     return Math.round(10 * sum) / 10.0;
            // } else {
            //     let val = values[0];
            //     let retVal = Math.round(val * 10.0) / 10.0;
            //     return retVal;
            // }
            return returnArray;
        } else {
            console.log("get Kpi values from OpenSearch: no hits for kpi", kpiName);
            return [];
        }
    } catch (error) {
        console.log(error);
    }

    return [];
};

// 2022-04-18: New functions to get values we need for the popular assets

// Asset specific
export const formatOpenSearchQueryForPopularAssets = (
    kpiName,
    orgId,
    groupItemId,
    createdBy,
    // assetIds, // MULTIPLE ASSET IDS
    timestampGte, // unix timestamp, milliseconds
    timestampLte, // unix timestamp, milliseconds
    size = 1
) => {
    let mustArray = [];

    // startDate Mon Oct 18 2021 00:00:00 GMT+0300 (Eastern European Summer Time) EventOverview.js:40
    // unix startDate 1634504400000 EventOverview.js:41
    // endDate Mon Oct 18 2021 23:59:59 GMT+0300 (Eastern European Summer Time) EventOverview.js:42
    // unix endDate 1634590799999

    if (kpiName !== undefined && kpiName !== null) {
        const nameTerm = {
            term: {
                name: kpiName,
            },
        };
        mustArray.push(nameTerm);
    }

    if (orgId !== undefined && orgId !== null) {
        const organizationTerm = {
            term: {
                orgId: orgId,
            },
        };
        mustArray.push(organizationTerm);
    }

    // Added for video-page-analytics
    if (groupItemId !== undefined && groupItemId !== null) {
        const groupItemTerm = {
            term: {
                groupItemId: groupItemId,
            },
        };
        mustArray.push(groupItemTerm);
    }

    // https://www.elastic.co/guide/en/elasticsearch/reference/current/query-dsl-terms-query.html

    // if (assetIds !== undefined && assetIds !== null) {
    //     const assetsTerm = {
    //         terms: {
    //             assetId: assetIds,
    //         },
    //     };
    //     mustArray.push(assetsTerm);
    // }

    // https://www.elastic.co/guide/en/elasticsearch/reference/current/search-your-data.html

    // if (assetId !== undefined && assetId !== null) {
    //     const assetTerm = {
    //         term: {
    //             assetId: assetId,
    //         },
    //     };
    //     mustArray.push(assetTerm);
    // } else if (eventId !== undefined && eventId !== null) {
    //     const eventTerm = {
    //         term: {
    //             eventId: eventId,
    //         },
    //     };
    //     mustArray.push(eventTerm);
    // } else {
    //     // no asset or event id
    if (createdBy !== undefined && createdBy !== null) {
        const creatorTerm = {
            term: {
                createdBy: createdBy,
            },
        };
        mustArray.push(creatorTerm);
    }
    // }

    // if (timestampGte !== undefined && timestampLte !== undefined && timestampGte !== null && timestampLte !== null) {
    //     const timestampRange = {
    //         range: {
    //             timestamp: {
    //                 gte: timestampGte,
    //                 lte: timestampLte,
    //             },
    //         },
    //     };
    //     mustArray.push(timestampRange);
    // }

    let query = {
        bool: {
            must: mustArray,
        },
    };

    // console.log("query", query);
    // console.log("query json", JSON.stringify(query));

    const data = {
        size: size,

        // https://www.elastic.co/guide/en/elasticsearch/reference/7.0/search-request-sort.html

        sort: [{ value: "desc" }], // sort by value

        // TODO: return groupItem?
        _source: ["timestamp", "value", "name", "id", "assetId", "createdBy"], // WHAT FIELDS ARE RETURNED
        query: query,
        // aggs: aggs,
    };

    // Logs out query we are doing
    // console.log("formatOpenSearchQueryForPopularAssets: OPENSEARCH QUERY ====>", kpiName, JSON.stringify(data));

    return data;
};

// size = how many to return

// getKpiValuesFromOpenSearchForPopularAssets("totalPlaysVOD", organizationId, createdBy, 20);

// Called from elsewhere
export const getKpiValuesFromOpenSearchForPopularAssets = async (kpiNickName, organizationId, groupItemId, createdBy, size) => {
    // console.log("get Kpi values From OpenSearch", kpiNickName);

    // console.log("get Kpi values from OpenSearch for popular assets: organizationId : " + organizationId);
    // console.log("get Kpi values from OpenSearch for popular assets: groupItemId : " + groupItemId);
    // console.log("get Kpi values from OpenSearch for popular assets: createdBy      : " + createdBy);

    // const days = getDays(startDate, endDate);

    // TODO: ignore createdBy for now
    createdBy = null;

    let assetId = 1; //assetIds[0]; // for getKpiName
    let eventId = null;
    let startDate = null;
    let endDate = null;
    // let days = null;

    try {
        // Other than totals
        // Never use minute precision for these as we are not drawing a graph

        const minutePrecision = false; //days <= 2;

        let kpiName = getKpiName(kpiNickName, minutePrecision, organizationId, groupItemId, createdBy, assetId, eventId);

        if (kpiName === "") {
            console.error("get Kpi values from OpenSearch for popular assets: KPI '" + kpiNickName + "' not implemented!");
            return [];
        }

        // const size = 10; // how many to return

        const query = formatOpenSearchQueryForPopularAssets(kpiName, organizationId, groupItemId, createdBy, /*assetIds,*/ startDate, endDate, size);

        console.log("-----> NEW QUERY", JSON.stringify(query));

        // console.log("OPENSEARCH QUERY ====>", kpiName, JSON.stringify(query));

        const res = await makeOpenSearchQuery(query);

        const hits = getHitsFromOpenSearchResponse(res);
        //if (hits && hits.length > 0 && hits[0]._source) {
        if (hits) {
            if (hits.length <= 0) {
                // empty array
                console.log("get Kpi values from OpenSearch for popular assets: empty array for kpi", kpiName);
                return [];
            }

            // console.log("----------> HITS", hits);

            let returnArray = hits.map((hit) => {
                return hit._source ? { assetId: hit._source.assetId, value: hit._source.value } : null;
            });

            // const values = hits.map((hit) => (hit._source ? Number(hit._source.value) : 0));
            // console.log("get Kpi values from OpenSearch: kpiNickName, kpiName, days, hits, values", kpiNickName, kpiName, days, hits, values);
            // if (makeAddition) {
            //     const reducer = (previousValue, currentValue) => previousValue + currentValue;
            //     const sum = values.reduce(reducer);
            //     return Math.round(10 * sum) / 10.0;
            // } else {
            //     let val = values[0];
            //     let retVal = Math.round(val * 10.0) / 10.0;
            //     return retVal;
            // }
            return returnArray;
        } else {
            console.log("get Kpi values from OpenSearch for popular assets: no hits for kpi", kpiName);
            return [];
        }
    } catch (error) {
        console.log(error);
    }

    return [];
};
