import { Link } from "react-router-dom";

import classes from "./HomeLink.module.css";

const HomeLink = (props) => {
    let linkToHome = "/analytics/vod/dashboard";

    // Without these, when we get back to the dashboard and select another video page,
    // we get not authorized.
    linkToHome += "?organizationId=" + props.organizationId;
    linkToHome += "&secret=" + props.secret;
    linkToHome += "&groupItemId=" + props.groupItemId;

    // console.log("HOMELINK", linkToHome);

    return (
        <div>
            <nav className={classes.homeNav}>
                <ul className={classes.homeNavLink}>
                    <Link to={linkToHome}>
                        <li>HOME</li>
                    </Link>
                </ul>
            </nav>
        </div>
    );
};

export default HomeLink;
